import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useGridLayout } from 'hooks/useGridLayout';
import { CampaignDto } from 'services/Campaign/campaignService.dto';

import CampaignTile from './CampaignTile/CampaignTile';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 24px;
  padding: 24px 0 24px 0;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

interface Props {
  campaigns: CampaignDto[];
  hasNextPage: boolean;
}
export const CampaignsTiles = ({ campaigns, hasNextPage }: Props) => {
  const navigate = useNavigate();
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleCampaigns = useGridLayout(campaigns, gridRef, hasNextPage);

  return (
    <TilesContainer ref={gridRef}>
      {visibleCampaigns.map(campaign => (
        <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
      ))}
    </TilesContainer>
  );
};
