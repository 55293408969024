import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { ShowAllButton } from 'components/buttons/ShowAllButton';
import { SortingDropdown } from 'components/inputs/SortingDropdown';
import { HomeItemTileList } from 'components/item/ItemList/HomeItemTileList';
import { H4 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { ClearHotDealsFiltersPanel } from 'features/item/components/hot-deals/filters/ClearHotDealsFiltersPanel';
import { useHomeItems } from 'features/item/hooks/useItems';
import { useResponsive } from 'hooks/useResponsive';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';
import { ItemSortType } from 'services/sortType';

import { DeliverySection } from './DeliverySection';
import { HotDealsNotFound } from './not-found/HotDealsNotFound';

const Container = styled.div`
  padding: 48px 24px 48px 48px;

  ${theme.mq.tablet} {
    padding: 24px 36px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  ${theme.mq.desktop} {
    align-items: start;
  }

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FilterButton = styled(RedTextButton)`
  border: 1px solid ${theme.color.red};
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;

  ${theme.mq.desktop} {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
`;

const StyledSpinner = styled(Spinner)`
  height: 1000px;
  padding-top: 60px;
  justify-content: flex-start;
`;

interface Props {
  toggleFilters: () => void;
}

export const ItemsList = ({ toggleFilters }: Props) => {
  const { t } = useTranslation('item');
  const { hotDealsCategories } = useGlobalData();
  const { filters, updateFilters, pageState, resetFilters } = useHotDealsFilters();
  const { state, fetchNextPage } = useHomeItems(filters, pageState);
  const { isTablet, isMobile } = useResponsive();
  const hasItems = state.items.length > 0;

  const sortOptions = [
    { value: 'RELEVANT', label: t('option.relevant') },
    { value: 'RECENT', label: t('option.recent') },
    { value: 'NEAREST', label: t('option.nearest') },
    { value: 'BEST_MATCH', label: t('option.best-match') },
    { value: 'CHEAPEST', label: t('option.cheapest') },
  ];

  const selectedCategoryName = filters.categoryCode
    ? hotDealsCategories.find(category => category.code === filters.categoryCode)?.displayName
    : null;

  return (
    <Container data-testid={'items-list-container'}>
      <HeaderContainer data-testid={'items-list-header'}>
        <InnerContainer data-testid={'items-list-inner-container'}>
          <H4 data-testid={'items-list-title'}>{selectedCategoryName || t('selected-for-you')}</H4>
          {!filters.categoryCode && <DeliverySection data-testid={'items-list-delivery-section'} />}
        </InnerContainer>
        {pageState === 'Filtered' && (
          <SortContainer data-testid={'items-list-sort-container'}>
            {!isMobile && <Text2 data-testid={'items-list-sort-label'}>{t('sort-by')}</Text2>}
            <SortingDropdown
              sortType={filters.sortType as ItemSortType}
              onSortChange={sortType => updateFilters({ sortType })}
              sortOptions={sortOptions}
              variant={'item'}
              data-testid={'items-list-sorting-dropdown'}
            />
          </SortContainer>
        )}
        {isTablet && <FilterButton onClick={toggleFilters}>{t('filters-btn')}</FilterButton>}
        {pageState === 'Default' && <ShowAllButton onClick={() => updateFilters({})} />}
      </HeaderContainer>
      <ClearHotDealsFiltersPanel />
      {hasItems ? (
        <>
          <HomeItemTileList
            items={state.items}
            data-testid={'items-list-home-item-tile-list'}
            hasNextPage={state.hasNextPage}
          />
          <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
        </>
      ) : !state.loading ? (
        <HotDealsNotFound resetFilters={resetFilters} />
      ) : (
        <StyledSpinner />
      )}
    </Container>
  );
};
