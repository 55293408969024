import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { hotDealsBreakpointsConfig } from 'components/featuredSection/featuredConfig';
import { TileSlider } from 'components/tile-slider/TileSlider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';

import { CategoryTile } from './CategoryTile';

const Container = styled.div`
  width: 100%;
  padding: 48px 24px 0 48px;

  ${theme.mq.tablet} {
    padding: 24px 36px 0 36px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

export const CategoryTileSlider = () => {
  const { hotDealsCategories } = useGlobalData();
  const { t } = useTranslation('item');
  const { pageState } = useHotDealsFilters();

  if (pageState === 'Filtered') return null;

  return (
    <Container data-testId={'category-slider'}>
      <TileSlider
        header={t('filters.categories')}
        items={hotDealsCategories}
        renderItem={category => <CategoryTile category={category} />}
        breakPointConfig={hotDealsBreakpointsConfig}
        testIdPrefix={'categories'}
      />
    </Container>
  );
};
