import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import searchIcon from 'assets/svg/search.svg';
import { RowContainer } from 'components/containers/Containers';
import { SearchType } from 'services/searchTypes';

import { useSearchBoxNavigate } from './hooks/useSearchBoxNavigate';
import SearchSuggestions from './SearchSuggestions';
import { SearchTypeDropdown } from './SearchTypeDropdown';
import { useSearchBoxData } from './useSearchBoxData';

const MAX_INPUT_LENGTH = 128;

const Container = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: ${({ isExpanded }) => (isExpanded ? '25px 25px 0 0' : '25px')};
  background-color: ${theme.color.lighterGray};
  box-shadow: ${({ isExpanded }) => (isExpanded ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
`;

const SearchBar = styled(RowContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const SearchInputContainer = styled(RowContainer)`
  flex: 1;
  padding-left: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  padding-left: 15px;
`;

const SearchIcon = styled.img`
  margin-right: 15px;
  width: 13px;
`;

const ITEMS_PATH = 'items';

const resolveSearchType = (pathName: string): SearchType => {
  if (pathName.includes(ITEMS_PATH)) return 'Items';
  return 'Campaigns';
};

const SearchBox = () => {
  const location = useLocation();
  const [searchType, setSearchType] = useState<SearchType>(resolveSearchType(location.pathname));
  const { t } = useTranslation();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { navigateToTarget } = useSearchBoxNavigate();
  const { recentSearches, saveSearch } = useSearchBoxData();

  const handleSearchTypeChange = (type: SearchType) => {
    setSearchType(type);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.slice(0, MAX_INPUT_LENGTH);
    setSearchPhrase(inputValue);
    setShowSuggestions(!!inputValue || recentSearches.length > 0);
  };

  const handleSuggestionClick = (searchType: SearchType, searchPhrase: string) => {
    saveSearch(searchType, searchPhrase);
    setShowSuggestions(false);

    navigateToTarget(searchType, searchPhrase);
    setSearchPhrase('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchPhrase) {
      saveSearch(searchType, searchPhrase);
      setShowSuggestions(false);

      navigateToTarget(searchType, searchPhrase);
      setSearchPhrase('');
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => setSearchType(resolveSearchType(location.pathname)), [location.pathname]);

  return (
    <Container ref={containerRef} isExpanded={showSuggestions} data-testid={'search-box-container'}>
      <SearchBar data-testid={'search-box-bar'}>
        <SearchInputContainer data-testid={'search-input-container'}>
          <SearchIcon src={searchIcon} data-testid={'search-icon'} />
          <SearchInput
            data-testid={'search-input'}
            type='text'
            placeholder={t('navbar.search')}
            value={searchPhrase}
            onChange={handleInputChange}
            onFocus={() => setShowSuggestions(!!searchPhrase || recentSearches.length > 0)}
            onKeyUp={handleKeyPress}
            enterKeyHint={'go'}
          />
        </SearchInputContainer>
        <RowContainer>
          <SearchTypeDropdown
            onMenuOpen={() => setShowSuggestions(false)}
            searchType={searchType}
            onTypeChange={handleSearchTypeChange}
          />
        </RowContainer>
      </SearchBar>

      {showSuggestions && (
        <SearchSuggestions
          searchPhrase={searchPhrase}
          recentSearches={recentSearches}
          searchType={searchType}
          onSuggestionClick={handleSuggestionClick}
        />
      )}
    </Container>
  );
};
export default SearchBox;
