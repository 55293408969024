import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { EasyGiving } from 'components/ui-elements/EasyGiving';
import Spinner from 'components/ui-elements/Spinner';
import { CampaignPreview } from 'features/campaign/CampaignPreview';
import { useFundraiserPage } from 'providers/FundraiserPageProvider';

import { CampaignsList } from './components/CampaignsList';
import { FundraiserDetails } from './components/FundraiserDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FundraiserPagePreview = () => {
  const { subdomainName } = useParams();
  const { fundraiserPage, fetchFundraiserPage, isLoading } = useFundraiserPage();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFundraiserPage(subdomainName);
  }, [subdomainName]);

  useEffect(() => {
    if (isLoading) return;
    if (!fundraiserPage) navigate('/');
  }, [isLoading, fundraiserPage]);

  if (isLoading) return <Spinner />;
  if (!fundraiserPage) return <Spinner />;
  if (fundraiserPage.defaultDisplayedCampaignId)
    return <CampaignPreview campaignId={fundraiserPage.defaultDisplayedCampaignId} />;

  const name = `${fundraiserPage.firstName} ${fundraiserPage.lastName}`;
  return (
    <Container data-testid={'fundraiser-page-preview'}>
      <FundraiserDetails name={name} about={fundraiserPage.about} joinDate={fundraiserPage.joinDate} />
      <CampaignsList ownerId={fundraiserPage.userId} />
      <EasyGiving />
    </Container>
  );
};
