import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RadioButton from 'components/buttons/RadioButton';
import { Text1Bold } from 'components/typography/Texts';
import { CampaignStatusType } from 'services/statusTypes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Title = styled(Text1Bold)`
  margin-bottom: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface StatusProps {
  selectedStatus: string;
  onStatusChange: (status: CampaignStatusType | undefined) => void;
}

export const Status = ({ selectedStatus, onStatusChange }: StatusProps) => {
  const { t } = useTranslation('campaign');

  const handleChange = (id: number, type: CampaignStatusType | undefined) => {
    onStatusChange(type);
  };

  const options: { id: number; title: string; type: CampaignStatusType }[] = [
    { id: 1, title: t('filters.status.all'), type: 'ALL' },
    { id: 2, title: t('filters.status.active'), type: 'ACTIVE' },
    { id: 3, title: t('filters.status.closed'), type: 'CLOSED' },
  ];

  return (
    <Container data-testid={'status-container'}>
      <Title data-testid={'status-title'}>{t('filters.status.title')}</Title>
      {options.map(option => (
        <ButtonContainer data-testid={`status-${option.type}`} key={option.id}>
          <RadioButton
            type='radio'
            value={option.type}
            checked={selectedStatus === option.type}
            onChange={() => handleChange(option.id, option.type)}>
            {option.title}
          </RadioButton>
        </ButtonContainer>
      ))}
    </Container>
  );
};
