import { extractSubdomainFromHost } from 'utils/urlUtils';
import { resolveFundraiserPageUrl, resolveLandingUrl } from 'utils/webRedirections';

export const Home = () => {
  const { hostname } = window.location;
  const subdomainName = extractSubdomainFromHost(hostname);
  if (!!subdomainName && subdomainName !== 'web') window.location.href = resolveFundraiserPageUrl(subdomainName);
  else window.location.href = resolveLandingUrl();
  return null;
};
