import styled from 'styled-components';

import closeIcon from 'assets/svg/close.svg';
import { Text1 } from 'components/typography/Texts';

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin: 4px;
`;
interface Props {
  onClose: () => void;
  label?: string;
  testId?: string;
}

export const SelectedFilter = ({ onClose, label, testId }: Props) => {
  return (
    <FilterContainer data-testid={testId}>
      <CloseIcon src={closeIcon} onClick={onClose} data-testid={`${testId}-close-icon`} />
      <Text1>{label}</Text1>
    </FilterContainer>
  );
};
