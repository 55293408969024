import { RefObject, useEffect, useState } from 'react';

import { getGridColumnCount, sliceTilesToFitRows } from 'utils/gridLayoutUtils';

export const useGridLayout = <T>(elements: T[], gridRef: RefObject<HTMLElement | null>, hasNextPage: boolean) => {
  const [visibleElements, setVisibleElements] = useState(elements);

  useEffect(() => {
    if (gridRef.current) {
      const columnCount = getGridColumnCount(gridRef.current);
      setVisibleElements(sliceTilesToFitRows(elements, columnCount, hasNextPage));
    }
  }, [elements]);

  return visibleElements;
};
