export const resolveLandingUrl = () => {
  return process.env.REACT_APP_LANDING_URL || '';
};

export const resolveAboutUsUrl = () => {
  return resolveLandingUrl() + 'about';
};

export const resolvePrivacyPolicyUrl = () => {
  return resolveLandingUrl() + 'privacy-policy';
};

export const resolveTermsUrl = () => {
  return resolveLandingUrl() + 'terms-of-service';
};

export const resolveHomePageUrl = () => {
  return `http://${process.env.REACT_APP_DOMAIN_NAME || ''}/`;
};

export const resolveFundraiserPageUrl = (subdomainName?: string) => {
  return resolveHomePageUrl() + 'fundraisers/' + subdomainName;
};
