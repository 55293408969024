import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Checkbox } from 'components/inputs/Checkbox';
import { Text1Bold } from 'components/typography/Texts';
import { ItemCategoryDto } from 'services/Item/itemService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 250px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.color.white});
    pointer-events: none;
  }
`;

const Title = styled(Text1Bold)`
  margin-bottom: 12px;
`;

const CategoryList = styled.ul`
  list-style: none;
  padding: 0 0 24px 0;
  margin: 0;
  overflow-y: auto;
`;

const CategoryItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  categories: ItemCategoryDto[];
  selectedCategory: string | undefined;
  onCategoryChange: (category: string | undefined) => void;
}

export const HotDealsCategories = ({ categories, selectedCategory, onCategoryChange }: Props) => {
  const { t } = useTranslation('item');

  const handleCategoryChange = (code: string) => {
    const newCategory = selectedCategory === code ? undefined : code;
    onCategoryChange(newCategory);
  };

  return (
    <Container data-testid={'hot-deals-categories-container'}>
      <Title data-testid={'hot-deals-categories-title'}>{t('filters.categories')}</Title>
      <CategoryList data-testid={'hot-deals-categories-list'}>
        {categories.map(category => (
          <CategoryItem key={category.code} data-testid={`hot-deals-category-item-${category.code}`}>
            <Label key={category.categoryOrder} data-testid={`hot-deals-category-label-${category.code}`}>
              <Checkbox
                data-testid={`hot-deals-category-checkbox-${category.code}`}
                type='checkbox'
                checked={selectedCategory === category.code}
                onChange={() => handleCategoryChange(category.code)}
              />
              {category.displayName}
            </Label>
          </CategoryItem>
        ))}
      </CategoryList>
    </Container>
  );
};
