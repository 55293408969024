import { useEffect, useState } from 'react';
import { AxiosPromise } from 'axios';

import PageableType from 'services/pageableType';

import { CampaignDto } from './campaignService.dto';

type CampaignState = {
  campaigns: CampaignDto[];
  page: number;
  loading: boolean;
  error: boolean;
  hasNextPage: boolean;
};

const initialState: CampaignState = {
  campaigns: [],
  page: 0,
  loading: false,
  error: false,
  hasNextPage: true,
};

export const useFetchCampaigns = (provider: (page: number) => AxiosPromise<PageableType<CampaignDto>>) => {
  const [state, setState] = useState<CampaignState>(initialState);

  useEffect(() => {
    if (state === initialState) {
      fetchNextPage();
    }
  }, [state]);

  const loadData = async () => {
    const response = await provider(state.page);
    const { content, last } = response.data;
    return { data: content || [], last };
  };

  const fetchNextPage = async () => {
    if (!state.hasNextPage || state.loading) return;

    setState(prevState => ({ ...prevState, loading: true }));

    try {
      const { data, last } = await loadData();
      setState(prevState => ({
        ...prevState,
        campaigns: prevState.page === 0 ? data : [...prevState.campaigns, ...data],
        hasNextPage: !last,
        page: prevState.page + 1,
      }));
    } catch (error) {
      setState(prevState => ({ ...prevState, error: true }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const refresh = () => {
    setState(initialState);
  };

  return {
    fetchNextPage,
    state,
    refresh,
  };
};
