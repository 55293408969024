import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import sendLocationIcon from 'assets/svg/send-location.svg';
import { Caption, Text1Bold } from 'components/typography/Texts';
import { useNotification } from 'providers/NotificationProvider';
import { shipmentService } from 'services/Shipment/shipmentService';
import { GeocodingDto } from 'services/Shipment/shipmentService.dto';

const ZIP_MAX_LENGTH = 5;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(Text1Bold)`
  margin-bottom: 12px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.color.lighterGray};
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  width: 50%;

  &::placeholder {
    color: ${theme.color.placeholderGrey};
  }
`;

const SendButton = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

const CityName = styled(Caption)`
  cursor: unset;
  color: ${theme.color.placeholderGrey};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface Props {
  cityName?: string;
  onLocationChange: (location: GeocodingDto) => void;
}

export const Location = ({ onLocationChange, cityName }: Props) => {
  const { t } = useTranslation('campaign');
  const [zipCode, setZipCode] = useState('');
  const { showNotification } = useNotification();

  const handleSend = () => {
    if (zipCode) {
      shipmentService
        .fetchGeocoding(zipCode)
        .then(location => {
          setZipCode('');
          onLocationChange(location.data);
        })
        .catch(() => handleError(zipCode));
    }
  };

  const handleError = (zipCode: string) => {
    showNotification({
      title: t('common:zip-not-found', { zipCode }),
      type: 'error',
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const validInput = value.replace(/[^0-9-]/g, '');
    setZipCode(validInput.substring(0, ZIP_MAX_LENGTH));
  };

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleSend();
    }
  };

  return (
    <LocationContainer data-testid={'location-container'}>
      <Title data-testid={'location-title'}>{t('filters.location')}</Title>
      <InputWrapper data-testid={'location-input-wrapper'}>
        <Input
          data-testid={'location-input'}
          maxLength={ZIP_MAX_LENGTH}
          type='text'
          placeholder={t('filters.zip-placeholder')}
          value={zipCode}
          onChange={handleChange}
          onKeyDown={e => handleKeyDown(e.key)}
        />
        <Container>
          {cityName && <CityName data-testid={'location-city-name'}>{cityName}</CityName>}
          <SendButton data-testid={'location-send-button'} src={sendLocationIcon} onClick={handleSend} />
        </Container>
      </InputWrapper>
    </LocationContainer>
  );
};
