import { campaignService } from './campaignService';
import { useFetchCampaigns } from './useFetchCampaigns';

interface Options {
  pageSize?: number;
}

const DEFAULT_PAGE_SIZE = 10;

const useMyCampaigns = (options?: Options) => {
  const pageSize = options?.pageSize ?? DEFAULT_PAGE_SIZE;
  return useFetchCampaigns(page => campaignService.fetchMyCampaigns(page, pageSize));
};

export default useMyCampaigns;
