import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import CampaignTile from 'components/campaign/CampaignTile/CampaignTile';
import { H4 } from 'components/typography/Headers';
import { useGridLayout } from 'hooks/useGridLayout';
import { useFundraiserCampaigns } from 'services/Campaign/useFundraiserCampaigns';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 100px 120px;
  border: 0 solid ${theme.color.darkGray};
  border-bottom-width: 1px;

  ${theme.mq.desktop} {
    padding: 40px 24px;
  }
`;

const Header = styled(H4)`
  margin-bottom: 50px;
`;

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 24px;

  ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  ownerId: number;
}

export const CampaignsList = ({ ownerId }: Props) => {
  const { t } = useTranslation('campaign');
  const navigate = useNavigate();
  const { state, fetchNextPage } = useFundraiserCampaigns(ownerId);
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleCampaigns = useGridLayout(state.campaigns, gridRef, state.hasNextPage);

  if (state.campaigns.length === 0) return <></>;
  return (
    <Container data-testid={'fundraiser-page-campaigns-list'}>
      <Header>{t('campaigns')}</Header>
      <TilesContainer ref={gridRef}>
        {visibleCampaigns.map(campaign => (
          <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
        ))}
      </TilesContainer>
      <LoadMoreButton
        data-testid={'fundraiser-page-load-more-button'}
        hasNextPage={state.hasNextPage}
        loading={state.loading}
        onClick={fetchNextPage}
      />
    </Container>
  );
};
