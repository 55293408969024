import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H5 } from 'components/typography/Headers';
import { Text1, Text2 } from 'components/typography/Texts';
import { parseBackendDate } from 'utils/backendDateParser';
import { formatDate } from 'utils/dateFormatter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 100px 0;
  border: 0 solid ${theme.color.darkGray};
  border-bottom-width: 1px;

  ${theme.mq.desktop} {
    padding: 50px 0;
  }

  ${theme.mq.tablet} {
    padding: 50px 24px;
  }

  ${theme.mq.phone} {
    padding: 20px 12px;
  }
`;

const JoinDate = styled(Text2)`
  color: ${theme.color.messageGray};
  margin-top: 10px;
`;

const About = styled(Text1)`
  width: 800px;
  margin-top: 25px;
  overflow-wrap: break-word;

  ${theme.mq.tablet} {
    width: 100%;
  }
`;

interface Props {
  name: string;
  about?: string;
  joinDate: string;
}

export const FundraiserDetails = ({ name, about, joinDate }: Props) => {
  const { t } = useTranslation('user');
  return (
    <Container>
      <H5 data-testid={'fundraiser-page-name'}>{name}</H5>
      <JoinDate data-testid={'fundraiser-page-join-date'}>
        {t('owner.join-date', { joinDate: formatDate(parseBackendDate(joinDate)) })}
      </JoinDate>
      {about && <About data-testid={'fundraiser-page-about'}>{about}</About>}
    </Container>
  );
};
