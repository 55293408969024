import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCampaignsSearchPhrase } from 'features/campaign/hooks/useCampaignsSearchPhrase';
import { useUserLocation } from 'features/campaign/hooks/useUserLocation';
import { SearchCampaignsParams } from 'services/Search/searchService.dto';
import { GeocodingDto } from 'services/Shipment/shipmentService.dto';
import { CampaignSortType } from 'services/sortType';
import { CampaignStatusType } from 'services/statusTypes';

import { DEFAULT_SORT_TYPE, PageState } from './filtersUtils';

export interface FiltersParams extends SearchCampaignsParams {
  cityName?: string;
}

export const noContentFilters: SearchCampaignsParams = {
  status: 'ACTIVE',
  category: [],
  sortType: DEFAULT_SORT_TYPE,
  size: 6,
};

const initFilters: FiltersParams = {
  category: [],
  sortType: DEFAULT_SORT_TYPE,
  status: undefined,
};

interface CampaignsFiltersContextType {
  filters: FiltersParams;
  pageState: PageState;
  updateFilters: (params: {
    sortType?: CampaignSortType;
    category?: string[];
    status?: CampaignStatusType;
    location?: GeocodingDto;
  }) => void;
  resetFilters: () => void;
  removeFilter: (filterType: 'category' | 'status' | 'location' | 'searchPhrase', value?: string) => void;
  hasActiveFilters: boolean;
}

const CampaignsFiltersContext = createContext<CampaignsFiltersContextType>(null!);

const CampaignsFiltersProvider = ({ children }: PropsWithChildren) => {
  const userLocation = useUserLocation();
  const navigate = useNavigate();

  const { searchPhrase, applySearchToFilters, applySearchToPageState } = useCampaignsSearchPhrase();
  const [pageState, setPageState] = useState<PageState>(applySearchToPageState('Default'));
  const [filters, setFilters] = useState<FiltersParams>(applySearchToFilters(initFilters));

  useEffect(() => {
    setFilters(prevFilters => applySearchToFilters(prevFilters));
    setPageState(prevState => applySearchToPageState(prevState));
  }, [searchPhrase]);

  const determinePageState = (newFilters: FiltersParams) => {
    const hasActiveFilters =
      (newFilters.category && newFilters.category.length > 0) ||
      newFilters.status !== undefined ||
      newFilters.lat !== undefined ||
      !!newFilters.searchPhrase;

    setPageState(hasActiveFilters ? 'Filtered' : 'Default');
  };

  const updateFilters = ({
    sortType,
    category,
    status,
    location,
  }: {
    sortType?: CampaignSortType;
    category?: string[];
    status?: CampaignStatusType;
    location?: GeocodingDto;
  }) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (sortType) {
        newFilters.sortType = sortType;
        newFilters.lat = undefined;
        newFilters.lon = undefined;
        if (sortType === 'NEAREST') {
          newFilters.lat = userLocation.lat;
          newFilters.lon = userLocation.lon;
        }
      }

      if (category) {
        newFilters.category = category;
        setPageState('Filtered');
      }

      if (status) {
        newFilters.status = status !== 'ALL' ? status : undefined;
        setPageState('Filtered');
      }

      if (location) {
        newFilters.sortType = 'NEAREST';
        newFilters.lat = location.coordinates.latitude;
        newFilters.lon = location.coordinates.longitude;
        newFilters.cityName = location.city;
        setPageState('Filtered');
      }

      return newFilters;
    });
  };

  const removeFilter = (filterType: 'category' | 'status' | 'location' | 'searchPhrase', value?: string) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (filterType === 'category' && newFilters.category) {
        newFilters.category = newFilters.category.filter(category => category !== value);
      } else if (filterType === 'status') {
        newFilters.status = undefined;
      } else if (filterType === 'location') {
        newFilters.lat = undefined;
        newFilters.lon = undefined;
        newFilters.cityName = undefined;
        newFilters.sortType = DEFAULT_SORT_TYPE;
      } else if (filterType === 'searchPhrase') {
        newFilters.searchPhrase = undefined;
        navigate({ search: undefined });
      }

      determinePageState(newFilters);
      return newFilters;
    });
  };

  const resetFilters = () => {
    setFilters(initFilters);
    setPageState('Default');
    navigate({ search: '' });
  };

  const hasActiveFilters =
    ((filters.category && filters.category.length > 0) ||
      filters.status !== undefined ||
      filters.lat !== userLocation.lat ||
      !!filters.searchPhrase) &&
    pageState === 'Filtered';

  return (
    <CampaignsFiltersContext.Provider
      value={{ filters, pageState, updateFilters, resetFilters, removeFilter, hasActiveFilters }}>
      {children}
    </CampaignsFiltersContext.Provider>
  );
};

const useCampaignsFilters = () => useContext(CampaignsFiltersContext);

export { CampaignsFiltersProvider, useCampaignsFilters };
