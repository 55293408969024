import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { DropdownInput } from 'features/my-profile/components/FormContainers';
import { CampaignSortType, ItemSortType } from 'services/sortType';

const StyledDropdown = styled(DropdownInput)`
  & .react-select {
    font-size: 16px;
  }

  & .react-select__control {
    cursor: pointer;
    border-color: ${theme.color.silver};
  }

  & .react-select__option {
    cursor: pointer;
  }

  & .react-select__option--is-selected {
    background-color: ${theme.color.red};
  }

  & .react-select__option--is-focused {
    background-color: rgba(252, 104, 111, 0.2);
  }

  & .react-select__option--is-selected.react-select__option--is-focused {
    background-color: ${theme.color.red};
  }
`;

interface Props {
  sortType: CampaignSortType | ItemSortType;
  onSortChange: (sortType: CampaignSortType | ItemSortType) => void;
  sortOptions: { value: string; label: string }[];
  variant: 'campaign' | 'item';
  testId?: string;
}

export const SortingDropdown = ({ sortType, onSortChange, sortOptions, variant, testId }: Props) => {
  const handleChange = (newSortType: string) => {
    if (variant === 'campaign') {
      onSortChange(newSortType as CampaignSortType);
    } else if (variant === 'item') {
      onSortChange(newSortType as ItemSortType);
    }
  };
  return (
    <StyledDropdown
      testId={testId}
      value={sortType}
      options={sortOptions}
      onChange={newSortType => handleChange(newSortType)}
      variant={'UPDATE'}
      useMenuPortal={false}
      isSearchable={false}
    />
  );
};
