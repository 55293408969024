import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { SelectedFilter } from 'components/filters/SelectedFilter';
import { Text1 } from 'components/typography/Texts';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';
import { ItemConditionDto } from 'services/Item/itemService.dto';
import { currencySymbol } from 'utils/numberFormatter';
import { truncateSearchPhrase } from 'utils/truncateSearchPhrase';

import { labelsConfig } from './filterOptionsConfig';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 8px 0;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.color.gray};
    border-radius: 3px;
  }
`;

const ClearFilters = styled(Text1)`
  cursor: pointer;
  flex-shrink: 0;
`;

const Separator = styled.div`
  border-left: 2px solid ${theme.color.darkGray};
  height: 10px;
  flex-shrink: 0;
`;

export const ClearHotDealsFiltersPanel = () => {
  const { t } = useTranslation('item');
  const { filters, resetFilters, removeFilter, hasActiveFilters, pageState } = useHotDealsFilters();
  const { hotDealsCategories, itemConditions } = useGlobalData();
  const selectedCategories = hotDealsCategories.filter(category => filters.categoryCode === category.code);
  const { cityName, conditionCode, sellingMethod, priceFrom, priceTo, deliveryType, radius, searchPhrase } = filters;
  const sellingMethodLabels = labelsConfig.sellingMethodOptions;
  const deliveryLabels = labelsConfig.deliveryType;
  const priceSelected = typeof priceFrom === 'number' && typeof priceTo === 'number';
  const priceLabel = `${currencySymbol()}${priceFrom} - ${currencySymbol()}${priceTo}`;

  const getSelectedConditions = (conditionCode: string, itemConditions: ItemConditionDto[]) => {
    if (!conditionCode) return [];
    return conditionCode
      .split(',')
      .map(code => itemConditions.find(condition => condition.code === code))
      .filter(Boolean);
  };

  const selectedConditions = getSelectedConditions(conditionCode!!, itemConditions);

  return (
    <Container data-testid={'clear-hot-deals-filters-panel'}>
      {pageState !== 'Default' && (
        <ClearFilters onClick={resetFilters} data-testid={'clear-filters-button'}>
          {t('filters.clear-all')}
        </ClearFilters>
      )}
      {hasActiveFilters && <Separator data-testid={'separator'} />}
      {priceSelected && (
        <SelectedFilter
          key={'price'}
          onClose={() => removeFilter('priceRange')}
          label={priceLabel}
          testId={'selected-filter-price'}
        />
      )}
      {selectedCategories.map(category => (
        <SelectedFilter
          key={category.categoryOrder}
          onClose={() => removeFilter('category', category.code)}
          label={category.displayName}
          testId={`selected-filter-category-${category.code}`}
        />
      ))}
      {cityName && (
        <SelectedFilter onClose={() => removeFilter('location')} label={cityName} testId={'selected-filter-location'} />
      )}
      {selectedConditions.map(condition => (
        <SelectedFilter
          key={condition?.code}
          onClose={() => removeFilter('condition', condition?.code)}
          label={condition?.shortDisplayName}
          testId={`selected-filter-condition-${condition?.code}`}
        />
      ))}
      {sellingMethod && (
        <SelectedFilter
          onClose={() => removeFilter('sellingMethod')}
          label={sellingMethodLabels[sellingMethod]}
          testId={'selected-filter-selling-method'}
        />
      )}
      {deliveryType !== 'All' && (
        <SelectedFilter
          onClose={() => removeFilter('deliveryType')}
          label={deliveryLabels[deliveryType!!]}
          testId={'selected-filter-delivery-type'}
        />
      )}
      {radius && (
        <SelectedFilter
          onClose={() => removeFilter('radius')}
          label={`${radius} ${t('filters.mile', { count: radius })}`}
          testId={'selected-filter-radius'}
        />
      )}
      {searchPhrase && (
        <SelectedFilter
          onClose={() => removeFilter('searchPhrase')}
          label={truncateSearchPhrase(searchPhrase)}
          testId={'selected-filter-search-phrase'}
        />
      )}
    </Container>
  );
};
