import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';

const StyledSelect = styled(Select)<{ $error: boolean; $success: boolean }>`
  ${containerStyle};
  ${fontStyle};
  padding: 0;

  .react-select__control {
    ${inputStyle};
  }

  ::placeholder {
    ${placeholderStyle}
  }
`;

interface Option {
  value: string;
  label: string;
}

interface Props extends BaseInputProps {
  options: Option[];
  onMenuOpen?: () => void;
  useMenuPortal?: boolean;
  isSearchable?: boolean;
}

const styles: StylesConfig = {
  menuPortal: base => ({
    ...base,
    zIndex: theme.zIndex.foremost,
  }),
};

const Dropdown = (props: Props) => {
  const {
    value,
    options,
    placeholder,
    errorMessage,
    successMessage,
    onChange,
    onMenuOpen,
    useMenuPortal,
    isSearchable,
  } = props;
  const { className, testId, disabled } = props;

  const handleChange = (newValue: any) => {
    onChange(newValue ? newValue.value : '');
  };

  return (
    <BaseInputField testId={testId} className={className} successMessage={successMessage} errorMessage={errorMessage}>
      <StyledSelect
        className='react-select'
        classNamePrefix='react-select'
        menuPosition={'fixed'}
        menuPortalTarget={useMenuPortal ?? true ? document.body : null}
        value={options.find(option => option.value === value)}
        placeholder={placeholder}
        options={options}
        isSearchable={isSearchable ?? true}
        isMulti={false}
        onChange={handleChange}
        $error={!!errorMessage}
        $success={!!successMessage}
        isDisabled={disabled}
        onMenuOpen={onMenuOpen}
        styles={styles}
      />
    </BaseInputField>
  );
};

export default Dropdown;
