import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import campaignIcon from 'assets/svg/camapign-icon.svg';
import hotDealsIcon from 'assets/svg/hot-deals.svg';
import { Text1 } from 'components/typography/Texts';
import { SearchType } from 'services/searchTypes';

const SuggestedIcon = styled.img`
  width: 15px;
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${theme.color.lighterGray};
  border-radius: 0 0 25px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${theme.color.lighterGray};
  }
`;

const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.lightGray};
  }

  &:last-child:hover {
    border-radius: 0 0 25px 25px;
  }
`;

const Spacer = styled.div`
  border-top: 1px solid ${theme.color.lightGray};
  margin: 0 20px;
`;

const SEARCH_TYPE_MAP = {
  Campaigns: {
    text: i18n.t('navbar.campaigns'),
    icon: campaignIcon,
    targetPath: '/campaigns',
  },
  Items: {
    text: i18n.t('navbar.hot-deals'),
    icon: hotDealsIcon,
    targetPath: '/hot-deals',
  },
};

interface SearchSuggestionsProps {
  searchPhrase: string;
  recentSearches: { type: SearchType; text: string }[];
  searchType: SearchType;
  onSuggestionClick: (searchType: SearchType, searchPhrase: string) => void;
}

const SearchSuggestions = ({ searchPhrase, recentSearches, searchType, onSuggestionClick }: SearchSuggestionsProps) => {
  const { t } = useTranslation();

  const inverseSearchType = searchType === 'Campaigns' ? 'Items' : 'Campaigns';
  const inverseSearchTypeText = SEARCH_TYPE_MAP[inverseSearchType].text;
  const inverseSearchTypeIcon = SEARCH_TYPE_MAP[inverseSearchType].icon;

  const resolveSearchTypeIcon = (type: SearchType) => SEARCH_TYPE_MAP[type].icon;

  return (
    <SuggestionsContainer data-testid={'search-suggestions-container'}>
      <Spacer data-testid={'suggestions-spacer'} />
      {recentSearches.map((item, index) => (
        <SuggestionItem
          key={index}
          onClick={() => onSuggestionClick(item.type, item.text)}
          data-testid={`suggestion-item-${index}`}>
          <SuggestedIcon src={resolveSearchTypeIcon(item.type)} data-testid={`suggested-icon-${index}`} />
          <Text1 data-testid={`suggested-text-${index}`}>{item.text}</Text1>
        </SuggestionItem>
      ))}
      {searchPhrase && (
        <>
          <Spacer data-testid={'suggestions-spacer'} />
          <SuggestionItem
            onClick={() => onSuggestionClick(inverseSearchType, searchPhrase)}
            data-testid={'suggestion-item-inverse'}>
            <SuggestedIcon src={inverseSearchTypeIcon} data-testid={`suggested-icon-${inverseSearchType}`} />
            <Text1 data-testid={'suggested-text-inverse'}>
              {t('search-box.suggested-search', {
                searchPhrase: searchPhrase,
                searchType: inverseSearchTypeText,
              })}
            </Text1>
          </SuggestionItem>
        </>
      )}
    </SuggestionsContainer>
  );
};

export default SearchSuggestions;
