import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { PlyrLayout, plyrLayoutIcons } from '@vidstack/react/player/layouts/plyr';
import styled from 'styled-components';

import '@vidstack/react/player/styles/base.css';
import '@vidstack/react/player/styles/plyr/theme.css';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

interface Props {
  videoUrl: string;
  autoplay: boolean;
  inactive?: boolean;
  onVideoStop?: () => void;
  onVideoPlay?: () => void;
}

const VideoPlayer = ({ videoUrl, inactive, autoplay, onVideoStop, onVideoPlay }: Props) => {
  return (
    <Wrapper className={'swiper-no-swiping'}>
      <MediaPlayer
        style={{ height: '100%', width: '100%' }}
        muted={autoplay}
        autoPlay={autoplay}
        src={videoUrl}
        playsInline={true}
        paused={inactive}
        onEnded={onVideoStop}
        onPause={onVideoStop}
        onPlay={onVideoPlay}>
        <MediaProvider style={{ borderRadius: 0 }} />
        <PlyrLayout icons={plyrLayoutIcons} />
      </MediaPlayer>
    </Wrapper>
  );
};

export default VideoPlayer;
