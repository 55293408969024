import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { SelectedFilter } from 'components/filters/SelectedFilter';
import { Text1 } from 'components/typography/Texts';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { truncateSearchPhrase } from 'utils/truncateSearchPhrase';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 8px 0;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.color.gray};
    border-radius: 3px;
  }
`;

const ClearFilters = styled(Text1)`
  cursor: pointer;
  flex-shrink: 0;
`;

const Separator = styled.div`
  border-left: 2px solid ${theme.color.darkGray};
  height: 10px;
  flex-shrink: 0;
`;

export const ClearCampaignsFiltersPanel = () => {
  const { t } = useTranslation('campaign');
  const { campaignCategories } = useGlobalData();
  const { filters, removeFilter, resetFilters } = useCampaignsFilters();
  const selectedCategories = campaignCategories.filter(category => filters.category?.includes(category.code));
  const { cityName, status, searchPhrase } = filters;

  return (
    <Container>
      <ClearFilters data-testid={'clear-filters-button'} onClick={resetFilters}>
        {t('filters.clear-all')}
      </ClearFilters>
      <Separator />
      {selectedCategories.map(category => (
        <SelectedFilter
          key={category.categoryOrder}
          onClose={() => removeFilter('category', category.code)}
          label={category.displayName}
          testId={`selected-filter-category-${category.code}`}
        />
      ))}
      {cityName && (
        <SelectedFilter onClose={() => removeFilter('location')} label={cityName} testId={'selected-filter-location'} />
      )}
      {status && (
        <SelectedFilter onClose={() => removeFilter('status')} label={status} testId={'selected-filter-status'} />
      )}
      {searchPhrase && (
        <SelectedFilter
          onClose={() => removeFilter('searchPhrase')}
          label={truncateSearchPhrase(searchPhrase)}
          testId={'selected-filter-search-phrase'}
        />
      )}
    </Container>
  );
};
