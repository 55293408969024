import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from '@mui/material';
import styled from 'styled-components';

import { Text1Bold } from 'components/typography/Texts';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';
import { PriceRangeDto } from 'services/Item/itemService.dto';

import { PriceSliderTick } from './PriceSilderTick';
import { PriceSliderInput } from './PriceSliderInput';

const DEFAULT_FREQUENCY_VALUES = new Array(50).fill(0);
const DEFAULT_PRICE_VALUES = new Array(51).fill(0);
const TICK_WIDTH = 2.5;
const TICK_HEIGHT = 70;
const TICK_MARGIN = 1;
const SLIDER_STEP = 100;

const Container = styled.div`
  width: 100%;
`;

const SliderContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 48%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.color.darkerGray};
    border-radius: 4px;
    text-align: center;
  }
`;

const CustomSlider = styled(Slider)`
  margin-top: 56px;
  & .MuiSlider-rail {
    height: ${TICK_WIDTH}px;
    background-color: ${({ theme }) => theme.color.lighterGray};
    opacity: 1;
  }

  & .MuiSlider-track {
    height: ${TICK_WIDTH}px;
    border: 1px solid ${({ theme }) => theme.color.red};
    background-color: ${({ theme }) => theme.color.red};
  }

  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.color.red};

    &:hover {
      box-shadow: none;
    }

    &::after {
      position: absolute;
      content: '';
      width: 15px;
      height: 15px;
      background-color: white;
    }
  }

  & .Mui-focusVisible {
    box-shadow: none !important;
  }

  & .MuiSlider-valueLabelOpen {
    color: black;
    background-color: transparent;
    font-size: 14px;
    top: 45px;
  }
`;

const PriceLine = styled.div`
  background: ${({ theme }) => theme.color.black};
  border-radius: 40px;
  height: 2px;
  width: 14px;
  margin: auto 10px;
`;

const CountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: space-between;
  position: absolute;
  width: 100%;
`;

const Title = styled(Text1Bold)`
  margin-bottom: 12px;
`;

interface Props {
  priceRange: PriceRangeDto;
}

export const PriceSliderFilter = ({ priceRange }: Props) => {
  const { t } = useTranslation('item');
  const { updateFilters, filters } = useHotDealsFilters();
  const [frequencyValues, setFrequencyValues] = useState(DEFAULT_FREQUENCY_VALUES);
  const [priceValues, setPriceValues] = useState(DEFAULT_PRICE_VALUES);
  const [range, setRange] = useState([priceValues[0], priceValues[priceValues.length - 1]]);

  useEffect(() => {
    if (priceRange) {
      setFrequencyValues(priceRange.itemFrequencies);
      setPriceValues(priceRange.priceRangeLimits);
    }
  }, [priceRange]);

  useEffect(() => {
    if (priceValues && priceValues.length > 0) {
      setRange([priceValues[0], priceValues[priceValues.length - 1]]);
    }
  }, [priceValues]);

  useEffect(() => {
    if (filters.priceFrom === undefined && filters.priceTo === undefined) {
      setRange([priceValues[0], priceValues[priceValues.length - 1]]);
    }
  }, [filters]);

  const minPossiblePrice = priceValues[0];
  const maxPossiblePrice = priceValues[priceValues.length - 1];

  const handleSliderValuesChanged = (newMin: number, newMax: number) => {
    const clampedMin = Math.max(minPossiblePrice, Math.min(newMin, maxPossiblePrice));
    const clampedMax = Math.max(minPossiblePrice, Math.min(newMax, maxPossiblePrice));

    setRange([clampedMin, clampedMax]);
  };

  const handleSliderChangeCommitted = () => {
    updateFilters({
      priceRange: { priceFrom: range[0], priceTo: range[1] },
    });
  };

  const handleMinInputChanged = (newMin: number | string) => {
    const parsedMin = Number(newMin);

    if (isNaN(parsedMin)) {
      setRange([0, range[1]]);
      return;
    }

    const clampedMin = Math.max(minPossiblePrice, Math.min(parsedMin, range[1]));
    setRange([clampedMin, range[1]]);
    updateFilters({
      priceRange: { priceFrom: clampedMin, priceTo: range[1] },
    });
  };

  const handleMaxInputChanged = (newMax: number | string) => {
    const parsedMax = Number(newMax);

    if (isNaN(parsedMax)) {
      setRange([range[0], 0]);
      return;
    }

    const clampedMax = Math.min(maxPossiblePrice, Math.max(parsedMax, range[0]));
    setRange([range[0], clampedMax]);
    updateFilters({
      priceRange: { priceFrom: range[0], priceTo: clampedMax },
    });
  };

  const isTickActive = (minPrice: number, maxPrice: number, index: number, priceValues: number[]): boolean => {
    const leftBorder = priceValues[index];
    const rightBorder = priceValues[index + 1];
    return minPrice <= leftBorder && maxPrice >= rightBorder;
  };

  const countTicks = frequencyValues.map((normalizedFrequency, index) => (
    <PriceSliderTick
      key={index}
      width={TICK_WIDTH}
      tickMargin={TICK_MARGIN}
      isFiltered={isTickActive(range[0], range[1], index, priceValues)}
      height={normalizedFrequency * TICK_HEIGHT}
      isLast={index === frequencyValues.length - 1}
    />
  ));

  return (
    <Container data-testid={'price-slider-filter-container'}>
      <Title data-testid={'price-slider-filter-title'}>{t('filters.price')}</Title>

      <SliderContainer data-testid={'price-slider-filter-slider-container'}>
        <CountWrapper data-testid={'price-slider-filter-ticks'}>{countTicks}</CountWrapper>
        <CustomSlider
          data-testid={'price-slider-filter-slider'}
          min={minPossiblePrice}
          max={maxPossiblePrice}
          value={range}
          step={SLIDER_STEP}
          onChange={(event, newValue) => {
            handleSliderValuesChanged((newValue as number[])[0], (newValue as number[])[1]);
          }}
          onChangeCommitted={handleSliderChangeCommitted}
        />
      </SliderContainer>

      <InputsWrapper>
        <PriceSliderInput
          data-testid={'price-slider-filter-min-input'}
          value={range[0]}
          onChange={value => handleMinInputChanged(Number(value))}
        />
        <PriceLine data-testid={'price-slider-filter-separator'} />
        <PriceSliderInput
          data-testid={'price-slider-filter-max-input'}
          value={range[1]}
          onChange={value => handleMaxInputChanged(Number(value))}
        />
      </InputsWrapper>
    </Container>
  );
};
