export const getGridColumnCount = (gridElement: HTMLElement): number => {
  if (!gridElement) throw new Error('grid Element is not defined');
  const computedStyle = window.getComputedStyle(gridElement);
  const columns = computedStyle.getPropertyValue('grid-template-columns');
  return columns.split(' ').length;
};

export const sliceTilesToFitRows = <T>(elements: T[], columnCount: number, hasNextPage: boolean): T[] => {
  if (elements.length < columnCount || elements.length % columnCount === 0 || !hasNextPage) return elements;

  const rowCount = Math.floor(elements.length / columnCount);
  const totalFullTiles = rowCount * columnCount;

  return elements.slice(0, totalFullTiles);
};
