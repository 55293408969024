import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { userService } from 'services/User/userService';
import { FundraiserPageDto } from 'services/User/userService.dto';

interface FundraiserPageContextType {
  fundraiserPage?: FundraiserPageDto;
  isLoading: boolean;
  fetchFundraiserPage: (subdomainName?: string) => void;
}

const ALLOWED_SUBDOMAIN_CHARACTERS_REGEX = /^(?!.*--)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;

const FundraiserPageContext = createContext<FundraiserPageContextType>(null!);

const FundraiserPageProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fundraiserPage, setFundraiserPage] = useState<FundraiserPageDto>();

  const fetchFundraiserPage = (subdomainName?: string) => {
    const isValid = !!subdomainName && ALLOWED_SUBDOMAIN_CHARACTERS_REGEX.test(subdomainName);
    if (!subdomainName || !isValid || fundraiserPage?.subdomainName === subdomainName) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    userService
      .fetchFundraiserPage(subdomainName)
      .then(response => setFundraiserPage(response.data))
      .catch(() => setFundraiserPage(undefined))
      .finally(() => setIsLoading(false));
  };

  return (
    <FundraiserPageContext.Provider value={{ fundraiserPage, isLoading, fetchFundraiserPage }}>
      {children}
    </FundraiserPageContext.Provider>
  );
};

const useFundraiserPage = () => useContext(FundraiserPageContext);

export { FundraiserPageProvider, useFundraiserPage };
