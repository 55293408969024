import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import appStoreLogo from 'assets/svg/app-store.svg';
import facebookIcon from 'assets/svg/facebook.svg';
import googlePlayLogo from 'assets/svg/google-play.svg';
import instagramIcon from 'assets/svg/instagram.svg';
import logoIcon from 'assets/svg/logo-full.svg';
import twitterIcon from 'assets/svg/twitter.svg';
import { IconWrapper, RowContainer } from 'components/containers/Containers';
import { H4 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import { useModal } from 'providers/ModalProvider';
import { appStoreLink, googlePlayLink } from 'utils/mobileStoreRedirections';
import { resolveFacebookUrl, resolveInstagramUrl, resolveTwitterUrl } from 'utils/socialMediaRedirections';
import { resolveAboutUsUrl, resolvePrivacyPolicyUrl, resolveTermsUrl } from 'utils/webRedirections';

import { Container, InnerContainer } from './footerContainers';

const LeftSection = styled.div`
  display: flex;
  background-color: white;
  min-width: 630px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 0;

  ${theme.mq.huge} {
    min-width: 420px;
  }

  ${theme.mq.tablet} {
    padding: 0;
    min-width: unset;
    flex: 1;
  }

  ${theme.mq.phone} {
    padding-top: 24px;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.color.red};
  color: ${theme.color.white};
  border-radius: 5px;
  padding: 68px;
  flex: 1;

  ${theme.mq.huge} {
    padding: 48px;
  }

  ${theme.mq.tablet} {
    padding: 24px;
    flex-direction: column;
  }
`;

const LogoSection = styled(RowContainer)`
  flex-direction: row;
  align-items: flex-start;

  ${theme.mq.tablet} {
    flex-direction: column;
  }
`;

const FollowUsSection = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: 108px;
  margin-top: 0;

  ${theme.mq.tablet} {
    margin-left: 0;
    margin-top: 24px;
  }
`;

const FollowUsText = styled(Text2)`
  font-weight: 700;
  color: ${theme.color.darkBlue};
  margin-bottom: 12px;
`;

const FollowUsContainer = styled(RowContainer)`
  gap: 5px;
`;

const LinksSection = styled(RowContainer)`
  margin-top: 60px;
  margin-bottom: 12px;

  ${theme.mq.phone} {
    margin-top: 24px;
  }
`;

const VerticalSpacer = styled.div`
  border-left: solid 1px ${theme.color.darkerGray2};
  margin: 0 10px;
  height: 100%;
`;

const LinkText = styled(Text2)`
  color: ${theme.color.darkBlue};
  cursor: pointer;
`;

const AddressText = styled(Text2)`
  color: ${theme.color.darkerGray};
  padding-right: 12px;
`;

const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 24px;
`;

const BannersSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;
  margin-top: 0;

  ${theme.mq.tablet} {
    margin-left: 0;
    margin-top: 30px;
  }
`;

const BannersHeader = styled(Text2)`
  font-weight: 700;
  margin-top: 6px;
`;

const BannersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;

  ${theme.mq.huge} {
    flex-direction: column;
    gap: 12px;
  }
`;

const Banner = styled.img`
  cursor: pointer;
  width: 167px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const DefaultFooter = () => {
  const { t } = useTranslation('common');
  const { showGetApp } = useModal();

  const displayGetApp = () => {
    showGetApp();
  };

  return (
    <Container>
      <InnerContainer>
        <LeftSection>
          <LogoSection>
            <IconWrapper src={logoIcon} onClick={displayGetApp} />
            <FollowUsSection>
              <FollowUsText>{t('footer.follow-us')}</FollowUsText>
              <FollowUsContainer>
                <a href={resolveFacebookUrl()} target='_blank' rel='noopener noreferrer'>
                  <IconWrapper src={facebookIcon} />
                </a>
                <a href={resolveInstagramUrl()} target='_blank' rel='noopener noreferrer'>
                  <IconWrapper src={instagramIcon} />
                </a>
                <a href={resolveTwitterUrl()} target='_blank' rel='noopener noreferrer'>
                  <IconWrapper src={twitterIcon} />
                </a>
              </FollowUsContainer>
            </FollowUsSection>
          </LogoSection>
          <LinksSection>
            <a href={resolveAboutUsUrl()}>
              <LinkText>{t('footer.about-us')}</LinkText>
            </a>
            <VerticalSpacer />
            <a href={resolvePrivacyPolicyUrl()}>
              <LinkText>{t('footer.privacy-policy')}</LinkText>
            </a>
            <VerticalSpacer />
            <a href={resolveTermsUrl()}>
              <LinkText>{t('footer.terms-of-use')}</LinkText>
            </a>
          </LinksSection>
          <AddressText>{t('footer.address')}</AddressText>
        </LeftSection>
        <RightSection>
          <WelcomeSection>
            <H4>{t('footer.welcome-title')}</H4>
            <Text2>{t('footer.welcome-subtitle')}</Text2>
          </WelcomeSection>
          <BannersSection>
            <BannersHeader>{t('footer.banners-header')}</BannersHeader>
            <BannersContainer>
              <a href={appStoreLink()} target='_blank' rel='noopener noreferrer'>
                <Banner src={appStoreLogo} />
              </a>
              <a href={googlePlayLink()} target='_blank' rel='noopener noreferrer'>
                <Banner src={googlePlayLogo} />
              </a>
            </BannersContainer>
          </BannersSection>
        </RightSection>
      </InnerContainer>
    </Container>
  );
};

export default DefaultFooter;
