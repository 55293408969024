export const resolveFacebookUrl = () => {
  return process.env.REACT_APP_FACEBOOK_URL || '';
};

export const resolveInstagramUrl = () => {
  return process.env.REACT_APP_INSTAGRAM_URL || '';
};

export const resolveTwitterUrl = () => {
  return process.env.REACT_APP_TWITTER_URL || '';
};
